jQuery(document).ready(function () {
    eqHeight();
});

jQuery(window).resize(function () {
    eqHeight();
});

jQuery(window).load(function () {
    eqHeight();

});

function eqHeight() {
    equalheightnoRow(".footer-col");

}

equalheight = function (container) {
    if (jQuery(window).width() > 767) {

        var currentTallest = 0,
                currentRowStart = 0,
                rowDivs = [],
                $el,
                topPosition = 0;
        jQuery(container).each(function () {
            $el = jQuery(this);
            jQuery($el).height("auto");
            topPostion = $el.offset().top;
            if (currentRowStart != topPostion) {
                for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                    rowDivs[currentDiv].innerHeight(currentTallest);
                }
                rowDivs.length = 0; // empty the array
                currentRowStart = topPostion;
                currentTallest = $el.innerHeight();
                rowDivs.push($el);
            } else {
                rowDivs.push($el);
                currentTallest = (currentTallest < $el.innerHeight()) ? ($el.innerHeight()) : (currentTallest);
            }
            for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                rowDivs[currentDiv].innerHeight(currentTallest);
            }
        });
    } else {
        jQuery(container).height("auto");
    }
};

equalheightnoRow = function (container) {
    if (jQuery(window).width() > 767) {
        var currentTallest = 0,
                currentRowStart = 0,
                rowDivs = [],
                jQueryel;

        jQuery(container).each(function () {
            jQueryel = jQuery(this);
            jQuery(jQueryel).innerHeight("auto");
            rowDivs.push(jQueryel);
            currentTallest = (currentTallest < jQueryel.innerHeight()) ? (jQueryel.innerHeight()) : (currentTallest);

            for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                rowDivs[currentDiv].innerHeight(currentTallest);
            }
        });
    } else {
        jQuery(container).height("auto");
    }
};

(function () {

    jQuery(".panel").on("show.bs.collapse hide.bs.collapse", function (e) {
        if (e.type == "show") {
            jQuery(this).addClass("active");
        } else {
            jQuery(this).removeClass("active");
        }
    });

}).call(this);

/**/
 jQuery(".banner-slider").slick({
    dots: (jQuery(this).find('.banner-slider .item').length > 1) ? true : false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1 ,
    autoplay: true,
    autoplaySpeed: 2000,

  });
